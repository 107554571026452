import React, { Component } from "react";
import axios from 'axios';

class ModelDevis extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnPhone: '',
            rnSubject: '',
            rnMessage: '',
            sent:false,
        };
    }


    formSubmit=(e)=>{
        e.preventDefault();
        if(this.state.rnName == ""){
            document.getElementById('nom').style.display="block";
        }
        else{
            document.getElementById('nom').style.display="none";
        }
        if(this.state.rnEmail == ""){
            document.getElementById('Email').style.display="block";
        }
        else{
            document.getElementById('Email').style.display="none";
        }
        if(this.state.rnPhone == ""){
            document.getElementById('telephone').style.display="block";
        }
        else{
            document.getElementById('telephone').style.display="none";
        }
        if(this.state.rnSubject == ""){
            document.getElementById('categ').style.display="block";
        }
        else{
            document.getElementById('categ').style.display="none";
        }
        if(this.state.rnName != "" && this.state.rnEmail != "" && this.state.rnPhone != "" && this.state.rnSubject != "" ){
        
        let data={
            rnName:this.state.rnName,
            rnEmail:this.state.rnEmail,
            rnPhone:this.state.rnPhone,
            rnSubject:this.state.rnSubject,
            rnMessage:this.state.rnMessage
        }
        axios.post('/formdmd',data)
        .then(res=>{
            this.setState({
                sent:true,
            },this.resetForm())
        }).catch(()=>{
            console.log('message not sent');
        })
    }
    }

        // for reset data
        resetForm=()=>{
            this.setState({
                rnName:'',
                rnEmail:'',
                rnPhone:'',
                rnSubject:'',
                rnMessage:''
            })
            setTimeout(()=>{
                this.setState({
                    sent:false,
                })
            },3000)
        }




    render(){
        return(
            <div className="contact-form--1">
                <div className="container-fluid">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-10 col-md-10 col-sm-12 order-2 order-lg-1" style={{margin:"0px auto"}}>
                            <div className="section-title text-left mb--50">
                                <h2 className="title titledemande" >Demandez votre devis !</h2>
                                <p className="description descriptiondemande ">Vous informer et échanger avec nous ne vous coûtera rien.</p>
                            </div>
                            <div className="form-wrapper">
                                <form onSubmit={this.formSubmit}>
                                    <div>
                                    <label htmlFor="item01" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 divdmd">
                                        <h5 className="titleIn">Nom & Prénom *</h5>
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            
                                        />
                                        <div className="errmsg" id="nom">Ce Champ est obligatoire</div>
                                    </label>

                                    <label htmlFor="item02" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 divdmd">
                                        <h5 className="titleIn">Email *</h5>
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                           
                                        />
                                        <div className="errmsg" id="Email">Ce Champ est obligatoire</div>
                                    </label>
                                    </div>
                                    <label htmlFor="item05" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 divdmd">
                                        <h5 className="titleIn">téléphone</h5>
                                        <input
                                            type="text"
                                            name="name"
                                            id="item05"
                                            value={this.state.rnPhone}
                                            onChange={(e)=>{this.setState({rnPhone: e.target.value});}}
                                            
                                        />
                                        <div className="errmsg" id="telephone">Ce Champ est obligatoire</div>
                                    </label>

                                    <label htmlFor="item03" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 divdmd">
                                        <h5 className="titleIn">Votre besoin *</h5>
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="(Exemple: site web, Apps ...)"
                                        />
                                        <div className="errmsg" id="categ">Ce Champ est obligatoire</div>
                                    </label>
                                    <label htmlFor="item04" className="col-lg-12 col-md-12 col-sm-12 col-xs-12 divdmd"> 
                                        <h5 className="titleIn">message</h5>
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Brève explication pour plus de détails"
                                        />
                                        
                                    </label>
                                    <div className={this.state.sent ?'msgAppear':'msg'}> Message a été Envoyé </div>
                                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12" style={{margin:"0px auto"}}> 
                                         <button className="rn-button-style--2 btn-solid col-12" type="submit" value="submit" name="submit" id="mc-embedded-subscribe" >Envoyer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}
export default ModelDevis;