import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class Solutions_Web extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Solutions Web' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--110 pb--100 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Créez un Site Web à <br /> Votre Image !</h2>
                                    <p>Une équipe proffessionelle à votre service</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">

                                            <div className="col-lg-6 col-12">
                                                <div className="details prl-20 mt_md--30 mt_sm--30">
                                                    <h4 className="title">Qui sommes nous ?</h4>
                                                    <p>Creapuls est une agence de conseil en communication & marketing avec un ADN 100% digital , nos
                                                        experts sont là pour vous assister tout au long du processus de création de votre site web. De la mise en
                                                        place de l’idée initiale jusqu'à la mise en ligne, </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100 w-88" src="/assets/images/service/online-web-design.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100 w-88" src="/assets/images/service/team-programmer-working.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details desserset mt_md--30 mt_sm--30">
                                                    <h4 className="title">Découvrez 3 raisons de créer un site web  </h4>
                                                    <ul className="liststyle">
                                                        <li><h4>Plus de Visibilité !</h4>Pour 68% des Français, le site web est la première source d'information avant l'acte d'achat.</li>
                                                        <li><h4>Augmentez vos ventes</h4>la vente en ligne a progressé de 95% durant les 3 dernière années</li>
                                                        <li><h4>Mieux connaître vos prospects et clients</h4>Les outils d'analyse de site web vous permettent de mieux connaître vos 
visiteurs en analysant leur parcours : quelles pages visitent-ils, pendant combien de temps.</li>
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 dmd-devis">
                            <h3>Qu’attendez-vous ? </h3>
                            <h2>Demandez votre devis </h2>
                            <div className="col-lg-6 col-md-12 sec-one">        
                                    <a className="rn-btn" href="/demandeDevis">
                                        <span>Demander un devis</span>
                                    </a>
                            </div>
                            <div className="col-lg-6 col-md-12 sec-two">        
                                    <a className="rn-btn" href="/designer-portfolio">
                                        <span>Voir Les Réferences</span>
                                    </a>
                            </div>

                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default Solutions_Web;