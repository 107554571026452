import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";


class ServiceTwo extends Component{
    render(){
        let title = 'Agence Web 360°',
        description = 'Spécialiste du Développement et Marketing Digital, Identité visuelle et de marque, Brand content, Création de site vitrine & boutique en ligne, Développement sur-mesure, Design UX&UI, Stratégie Digitale, Référencement Naturel, Référencement Payant, Social Média';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                {/*<a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" >
                                    <a href="/Applications_mobiles/">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                <FiMonitor />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">Développement Web & Mobile</h3>
                                                <p>● Création / Refonte de site web.</p>
                                                <p>● Gestion et maintenance de site web.</p>
                                                <p>● Application.</p>
                                                <p>● Landing page.</p>
                                                <p>● Développement sur mesure.</p> 
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" >
                                    <a href="/Creative/">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                <FiLayers />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">Community management</h3>
                                                <p>● Création et mise en place des pages, groupes grand public et pro : Facebook, Twitter ,YouTube, LinkedIn…</p>
                                                <p>● Animation de communautés , publication de contenus, modération et recommandations.</p>
                                                <p>● Suivi mensuel et reporting.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>


                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" >
                                    <a href="/Solutions_Web/">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                <FiCast />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">Conseil & stratégie</h3>
                                                <p>● Accompagnement stratégique.</p>
                                                <p>● Audit & veille concurrentielle.</p>

                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" >
                                    <a href="/marketing/">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                <FiUsers />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">Stratégie Marketing</h3>
                                                <p>● Google display network.</p>
                                                <p>● Facebook ads.</p>
                                                <p>● Tweeter ads.</p>
                                                <p>● Instagram ads.</p>
                                                <p>● Precision Marketing.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>


                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" >
                                    <a href="/Branding/">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                <FiUsers />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">Conception graphique</h3>
                                                <p>● Logo.</p>
                                                <p>● Conception d’univers graphique et de territoire visuel.</p>
                                                <p>● Charte Graphique.</p>
                                                <p>● Création de slogans.</p>
                                                <p>● Iconographie, illustration.</p>
                                                <p>● Bannière Pub Web.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
