import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Business Stratagy',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiLayers />,
        title: 'Website Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Mobile App Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Mobile App Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    
                        <div className={`${column}`}>
                            <a href="/Applications_mobiles/">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        <FiMonitor />
                                    </div>
                                    <div className="content">
                                        <h3 className="title">Développement Web & Mobile</h3>
                                        <p>Création / Refonte de site web, Gestion et maintenance de site web, les Application et plus.</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className={`${column}`}>
                            <a href="/Creative/">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        <FiLayers />
                                    </div>
                                    <div className="content">
                                        <h3 className="title">Community management</h3>
                                        <p>Création et mise en place des pages, groupes grand public et pro : Facebook, Twitter ,YouTube, LinkedIn…</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className={`${column}`}>
                            <a href="/Solutions_Web/">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        <FiCast />
                                    </div>
                                    <div className="content">
                                        <h3 className="title">Conseil & stratégie</h3>
                                        <p>Accompagnement stratégique, Audit & veille concurrentielle.</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className={`${column}`}>
                            <a href="/marketing/">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        <FiUsers />
                                    </div>
                                    <div className="content">
                                        <h3 className="title">Stratégie Marketing</h3>
                                        <p>Google display network, Facebook ads, Tweeter ads et Precision Marketing.</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className={`${column}`}>
                            <a href="/Branding/">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        <FiMonitor />
                                    </div>
                                    <div className="content">
                                        <h3 className="title">Conception graphique</h3>
                                        <p>Logo, Conception d’univers graphique et de territoire visuel, Charte Graphique et plus.</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                 
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
