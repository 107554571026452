import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'Qui Sommes Nous ?',
        description = 'Passionnés par notre travail dont nous sommes fiers, nous mettons tout en œuvre pour vous apporter notre expertise et notre expérience dans la réalisation de vos projets.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container-fluid">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.png" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                
                                                <p> De la réflexion sur le planning stratégique à la mise en place d’actions ciblées, notre équipe vous accompagne dans le succès de vos campagnes marketing et d’acquisition sur le digital </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Innovation , Créativité et Technologie Sont Nos Moteurs du Quotidien! </h3>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;