import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p> Certifications </p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>VOTRE AGENCE</span> de stratégie digitale</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p> Certifications </p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>NOS CERTIFICATIONS</span> témoignent de notre engagement</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p> Certifications </p>
                                    </div>
                                    <div className="author-info">
                                        <h6>facebook blue print , Google partner</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/DIGITAL-TRANS.png" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/google-ads-logo.png" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/Facebook-Certified.png" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;