import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const TabOne = [
    {
        image: '01',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-01.jpg',
        category: 'Mobile App',
        title: 'Mobile App landing Design',
        clsname: ''
    },
    {
        image: '02',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-02.jpg',
        category: 'Web Design',
        title: 'The service provide for designer',
        clsname:''
    },
    {
        image: '23',
        bigImage: '/assets/images/portfolio/dp-portfolio-23.jpg',
        category: 'Logo Design',
        title: 'Logo Design creativity',
        clsname:'objfit'
    },
    {
        image: '22',
        bigImage: '/assets/images/portfolio/dp-portfolio-22.jpg',
        category: 'Logo Design',
        title: 'Logo Design creativity',
        clsname:'objfit'
    },
    {
        image: '05',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-05.jpg',
        category: 'Web Design',
        title: 'The service provide for designer',
        clsname:''
    },
    {
        image: '06',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-06.jpg',
        category: 'Mobile App',
        title: 'Mobile App landing Design',
        clsname:''
    },
    {
        image: '07',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-07.jpg',
        category: 'Mobile App',
        title: 'Mobile App landing Design',
        clsname:''
    },
    {
        image: '28',
        bigImage: '/assets/images/portfolio/dp-portfolio-28.jpg',
        category: 'Logo Designer',
        title: 'Logo Design creativity',
        clsname:'objfit'
    },
    {
        image: '09',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Web Design',
        title: 'The service provide for designer',
        clsname:''
    },
   
]


const TabTwo = [
    {
        image: '02',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-02.jpg',
        category: 'Web Design',
        title: 'The service provide for designer'
    },
    {
        image: '21',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-21.jpg',
        category: 'Web Design',
        title: 'The service provide for designer'
    },
    {
        image: '09',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Web Design',
        title: 'The service provide for designer'
    },
    {
        image: '10',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-10.jpg',
        category: 'Web Design',
        title: 'The service provide for designer'
    },
    {
        image: '11',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-11.jpg',
        category: 'Web Design',
        title: 'The service provide for designer'
    },
    {
        image: '12',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-12.jpg',
        category: 'Web Design',
        title: 'The service provide for designer'
    },
    {
        image: '19',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-19.jpg',
        category: 'Web Design',
        title: 'The service provide for designer'
    },
    {
        image: '20',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-20.jpg',
        category: 'Web Design',
        title: 'The service provide for designer'
    },
    {
        image: '05',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-05.jpg',
        category: 'Web Design',
        title: 'The service provide for designer'
    },
]

const TabThree = [
    {
        image: '01',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-01.jpg',
        category: 'Mobile App',
        title: 'Mobile App landing Design'
    },
    {
        image: '14',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-14.jpg',
        category: 'Mobile App',
        title: 'Mobile App landing Design'
    },
    {
        image: '07',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-07.jpg',
        category: 'Mobile App',
        title: 'Mobile App landing Design'
    },
    {
        image: '13',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-13.jpg',
        category: 'Mobile App',
        title: 'Mobile App landing Design'
    },
    {
        image: '06',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-06.jpg',
        category: 'Mobile App',
        title: 'Mobile App landing Design'
    },
    {
        image: '19',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-19.jpg',
        category: 'Mobile App',
        title: 'Mobile App landing Design'
    },
]

const TabFour = [
    {
        image: '23',
        bigImage: '/assets/images/portfolio/dp-portfolio-23.jpg',
        category: 'Logo Design',
        title: 'Logo Design creativity'
    },
    {
        image: '22',
        bigImage: '/assets/images/portfolio/dp-portfolio-22.jpg',
        category: 'Logo Design',
        title: 'Logo Design creativity'
    },
    {
        image: '24',
        bigImage: '/assets/images/portfolio/dp-portfolio-24.jpg',
        category: 'Logo Design',
        title: 'Logo Design creativity'
    },
    {
        image: '25',
        bigImage: '/assets/images/portfolio/dp-portfolio-25.jpg',
        category: 'Logo Design',
        title: 'Logo Design creativity'
    },
    {
        image: '26',
        bigImage: '/assets/images/portfolio/dp-portfolio-26.jpg',
        category: 'Logo Design',
        title: 'Logo Design creativity'
    },
    {
        image: '27',
        bigImage: '/assets/images/portfolio/dp-portfolio-27.jpg',
        category: 'Logo Design',
        title: 'Logo Design creativity'
    },
    {
        image: '28',
        bigImage: '/assets/images/portfolio/dp-portfolio-28.jpg',
        category: 'Logo Design',
        title: 'Logo Design creativity'
    },
    {
        image: '29',
        bigImage: '/assets/images/portfolio/dp-portfolio-29.jpg',
        category: 'Logo Design',
        title: 'Logo Design creativity'
    },
    {
        image: '30',
        bigImage: '/assets/images/portfolio/dp-portfolio-30.jpg',
        category: 'Logo Design',
        title: 'Logo Design creativity'
    },
]

class TabStyleThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            tab2: 0,
            tab3: 0,
            tab4: 0,
            isOpen: false,
        };
    }
    render() {
        const {column } = this.props;
        const { tab1, tab2, tab3, tab4, isOpen } = this.state;
        return (
            <div>
                <Tabs>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <div className="tablist-inner">
                                <TabList className="pv-tab-button text-center mt--0">
                                    <Tab><span>Tout Projet</span></Tab>
                                    <Tab><span>Web Design</span></Tab>
                                    <Tab><span>Mobile App</span></Tab>
                                    <Tab><span>Logo Design</span></Tab>
                                </TabList>
                            </div>
                        </div>
                    </div>

                    <TabPanel className="row row--35">
                        {TabOne.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabOne[tab1].bigImage}
                                        nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                        prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab1: (tab1 + 1) % TabOne.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#">
                                                        <img src={`/assets/images/portfolio/dp-portfolio-${value.image}.jpg`} className={`custportfolio ${value.clsname}`} alt="Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>
            
                    <TabPanel className="row row--35">
                        {TabTwo.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabTwo[tab2].bigImage}
                                        nextSrc={TabTwo[(tab2 + 1) % TabTwo.length]}
                                        prevSrc={TabTwo[(tab2 + TabTwo.length - 1) % TabTwo.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab2: (tab2 + TabTwo.length - 1) % TabTwo.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab2: (tab2 + 1) % TabTwo.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab2: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#">
                                                        <img src={`/assets/images/portfolio/dp-portfolio-${value.image}.jpg`} className="custportfolio" alt="Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabThree.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabThree[tab3].bigImage}
                                        nextSrc={TabThree[(tab3 + 1) % TabThree.length]}
                                        prevSrc={TabThree[(tab3 + TabThree.length - 1) % TabThree.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab3: (tab3 + TabThree.length - 1) % TabThree.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab3: (tab3 + 1) % TabThree.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab3: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#">
                                                        <img src={`/assets/images/portfolio/dp-portfolio-${value.image}.jpg`} className="custportfolio" alt="Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabFour.map((value , index) => (
                            <div className={`${column}`} key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabFour[tab4].bigImage}
                                        nextSrc={TabFour[(tab4 + 1) % TabFour.length]}
                                        prevSrc={TabFour[(tab4 + TabFour.length - 1) % TabFour.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabFour.length - 1) % TabFour.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabFour.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <a href="#">
                                                        <img src={`/assets/images/portfolio/dp-portfolio-${value.image}.jpg`} className="custportfolio objfit" alt="Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><a href="#">{value.title}</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>



                </Tabs>
            </div>
        )
    }
}


export default TabStyleThree
